@import "fonts";
@import "mixins";
@import "vars";
@import "generated/sprite-svg";
@import "libs/animate-custom";
@import "libs/input-range";
@import "libs/custom-checkbox";
@import "libs/custom-radio";
@import "libs/slick";
@import "libs/slick-theme";
@import "libs/select2";
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  font-family: $font-primary;
  font-size: 16px;
}

.wrapper {
  width: 100%;
  font-family: $font-primary;
  color: #404852;
  letter-spacing: 0.5px;
  overflow: hidden;
}

.cont {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
}

a {
  transition: all 0.3s ease;
  color: inherit;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: #86d97e;
    [class^="icon-"],
    [class*=" icon-"] {
      fill: #86d97e;
    }
  }
}

[class^="icon-"],
[class*=" icon-"] {
  transition: all 0.3s ease;
}

.icon-phone {
  width: 16px;
  height: 16px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  width: 170px;
  height: 55px;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: #5ecd6e;
  border-radius: 4px;
  border: solid 1px #47bc55;
  background-color: #ffffff;
  transition: all 0.3s ease;
  @media only screen and (max-width: 480px) {
    height: 45px;
    font-size: 13px;
  }
  &:focus,
  &:hover {
    outline: none;
    cursor: pointer;
  }
}

a.button--green,
.button--green,
.button--green[type=submit] {
  margin-top: auto;
  color: #ffffff;
  border-color: transparent;
  border: none!important;
  background-color: #58b763;
  background-image: linear-gradient(128deg, #88cc7a, #58b763)!important;
  -webkit-appearance: none;
  &:hover {
    background-image: linear-gradient(-128deg, #88cc7a, #58b763);
  }
}

.oval-grey {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: rgba(106, 191, 108, 0.1);
  border-radius: 50%;
  .icon-study {
    width: 16px;
    height: 10px;
  }
  .icon-schedule {
    width: 11px;
    height: 14px;
  }
}

.border-top {
  border-top: solid 1.5px #ebeef6;
}

.color-green {
  color: #41bb4f;
}

.text-underlined {
  text-decoration: underline;
}

.text-small {
  font-size: 13px;
}

// HEADER
.header {
  max-width: 1190px;
  margin: 0 auto;
  padding-top: 31px;
  @media only screen and (max-width: 768px) {
    padding-top: 22px;
  }
  @media only screen and (max-width: 360px) {
    padding-top: 11px;
    .navbar {
      padding-left: 5px;
    }
  }
  &__logo img {
    width: 126px;
    @media only screen and (max-width: 360px) {
      width: 76px;
    }
  }
  &__navbar {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #ffffff;
    transform: translateY(-4px);
    @media only screen and (max-width: 992px) {
      padding-top: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    .navbar {
      padding: 0 0.5rem;
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    .icon-phone {
      margin-right: 11px;
    }
    @media only screen and (max-width: 360px) {
      font-size: 13px;
      .icon-phone {
        width: 11px;
        height: 11px;
        margin-right: 9px;
      }
    }
  }
  .main-menu {
    align-items: center;
    @media only screen and (max-width: 991px) {
      align-items: stretch;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
    .nav-item {
      &:not(:last-child) {
        margin-right: 1em;
        @media only screen and (max-width: 992px) {
          margin-right: 0;
        }
      }
      &:hover {
        .nav-link {
          // background-color: #fff;
          color: #86d97e;
        }
      }
      .dropdown-toggle::after {
        display: none;
      }
    }
    .nav-link {
      font-size: 14px;
      padding: .35em 8px;
      color: #ffffff;
      transition: all 0.3s ease;
      cursor: pointer;
      &.button {
        width: 128px;
        height: 45px;
        border-radius: 4px;
        border: solid 1px #ffffff;
        background-color: transparent;
        &:hover {
          border-color: #86d97e;
        }
      }
      @media only screen and (max-width: 991px) {
        padding-left: 0;
        &.button {
          margin-bottom: 10px;
          width: 100px;
          height: 35px;
        }
      }
    }
    .dropdown-menu {
      min-width: 10rem;
      padding: 0;
      margin: 0;
      font-size: 1.25em;
      border: none;
      border-radius: 0;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 8px 15px 0 rgba(0, 0, 0, 0.1);
    }
    .dropdown-item {
      color: #86d97e;
      padding: .25rem 0.6em;
      &:hover {
        color: #86d97e;
        background: transparent;
      }
      &:active {
        color: #ffffff;
        background-color: lighten(#86d97e, 10%);
      }
    }
  }
  .navbar-toggler {
    border: none;
    color: #fff;
    padding: 0;
    font-size: 1rem;
    &:focus {
      outline: none!important;
    }
  }
  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='3' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    @media only screen and (min-width: 480px) {
      margin-bottom: 0.7rem;
    }
  }
}

.hero {
  position: relative;
  min-height: 827px;
  color: #ffffff;
  padding-bottom: 76px;
  @media only screen and (max-width: 1400px) {
    min-height: 57.4vw;
    padding-bottom: 5.27vw;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #f5f6f8, #ffffff);
    z-index: -2;
  }
  &__text {
    max-width: 840px;
    margin: 189px auto 0;
    @include rfs(20);
    @media only screen and (max-width: 1400px) {
      margin-top: 10vw;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 45px;
    }
  }
  &__title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    @include rfs(50);
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    @media only screen and (max-width: 480px) {
      font-size: 20px;
    }
  }
  &__subtitle {
    margin-top: 30px;
    font-size: 14px;
    line-height: 2;
    text-align: left;
    color: #e7e6e6;
    @media only screen and (max-width: 1400px) {
      margin-top: 1.5vw;
    }
    @media only screen and (max-width: 480px) {
      margin-top: 16px;
      font-size: 13px;
      line-height: 1.38;
    }
  }
  &__title,
  &__subtitle {
    max-width: 650px;
    padding-left: 20px;
    @media only screen and (max-width: 992px) {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      text-align: center;
    }
  }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: auto;
    width: 100%;
    @media only screen and (max-width: 1200px) {
      width: calc(100% + 100px);
    }
    @media only screen and (max-width: 992px) {
      width: calc(100% + 150px);
    }
    @media only screen and (max-width: 580px) {
      width: auto;
      height: 370px;
    }
  }
}

.order-form {
  padding: 43px 50px;
  position: relative;
  margin: 0 auto;
  margin-top: 88px;
  max-width: 850px;
  color: #404852;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 40px 60px 0 rgba(145, 159, 188, 0.17);
  @media only screen and (max-width: 1400px) {
    margin-top: 6vw;
  }
  @media only screen and (max-width: 892px) {
    padding: 20px;
  }
  @media only screen and (max-width: 480px) {
    margin-top: 35px;
    padding: 20px 24px 10px;
  }
  &.max-width-840 {
    margin-top: 99px;
    max-width: 840px;
    padding: 40px 60px 21px;
    @media only screen and (max-width: 1400px) {
      margin-top: 6vw;
    }
    @media only screen and (max-width: 480px) {
      padding: 19px 25px 0;
      margin-top: 35px;
      font-size: 20px;
    }
  }
  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 20px;
    p.text-small {
      margin: 10px 0 0;
    }
  }
  .form__row {
    display: flex;
    justify-content: space-between;
  }
  .column-md-down {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      .form-group {
        width: 100%;
        max-width: 480px;
      }
      .button {
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: 360px) {
    .form__top {
      padding-left: 11px;
    }
  }
  @media only screen and (max-width: 360px) {
    .form__bottom {
      flex-direction: column;
      .button {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }
  .form__back {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #404852;
    &:hover {
      color: #6b7ea6;
      cursor: pointer;
    }
    img {
      margin-right: 12px;
    }
  }
  .form__title {
    margin: 1.95em 0 .4em;
    @include rfs(25);
    font-weight: 600;
  }
  .form__descr {
    margin-top: 5px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.75;
    letter-spacing: 0.6px;
    color: #797e85;
  }
  .form__block {
    padding: 42.5px 0;
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 16px;
      color: #9eabba;
      .oval-grey {
        margin-right: 20px;
      }
    }
    @media only screen and (max-width: 360px) {
      padding: 30px 0;
      &-header {
        font-size: 14px;
        line-height: 1.3;
        .oval-grey {
          display: none;
        }
      }
    }
    // useless classes begin
    @media only screen and (min-width: 480px) {
      &.pixel-perfect-padding {
        padding-top: 47px;
      }
      &.pixel-perfect-padding2 {
        padding-top: 30px;
      }
      &.pixel-perfect-padding3 {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    @media only screen and (max-width: 360px) {
      &.pixel-perfect-padding0 {
        padding-top: 8px;
      }
      &.pixel-perfect-padding {}
      &.pixel-perfect-padding2 {
        // padding-top: 30px;
      }
      &.pixel-perfect-padding3 {
        padding-top: 33px;
      }
    }
    // useless classes end
  }
  label {
    margin-bottom: 0.8rem;
    font-size: 16px;
    font-weight: 600;
    @media only screen and (max-width: 480px) {
      margin-bottom: .6rem;
      font-size: 13px;
    }
  }
  .form__elem {
    display: block;
    height: 55px;
    border-radius: 4px;
    border: solid 1px #bec9de;
    @media only screen and (max-width: 480px) {
      height: 45px;
    }
  }
  input,
  textarea {
    color: #404852;
    &::placeholder {
      opacity: 0.5;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.6px;
      color: darken(#bec9de, 10%);
      @media only screen and (max-width: 480px) {
        font-size: 13px;
      }
    }
  }
  textarea {
    width: 100%;
    min-height: 200px;
    resize: none;
    padding: 30px;
    &::placeholder {
      font-size: 13px;
    }
    @media only screen and (max-width: 1400px) {
      padding: 2vw;
    }
    @media only screen and (max-width: 480px) {
      min-height: 126px;
      padding: 16px 15px;
      &::placeholder {
        line-height: 1.7;
        font-size: 12px;
      }
    }
  }
  .form__row--description {
    margin-top: 25px;
    @media only screen and (max-width: 360px) {
      margin-top: 21px;
    }
  }
  // grade row
  .form__row--grade {
    margin-top: 6px;
    .radio__group {
      .radio__input-container {
        margin-top: 0;
        &:not(:last-child) {
          margin-right: 24px;
        }
        @media only screen and (max-width: 360px) {
          &:not(:last-child) {
            margin-right: 10px;
          }
          &:nth-child(even) {
            margin-right: 0;
          }
          .radio__item {
            width: 115px;
          }
        }
      }
    }
    .radio__item {
      flex-direction: column;
      width: 127px;
      min-height: 117px;
      padding: 25px 5px 10px;
      border-radius: 4px;
      border: solid 1px #d8dde8;
      .radio__icon {
        width: 40px;
        height: 40px;
        margin-right: 0;
        border: none;
        border-radius: 0;
        border: none;
        background-color: #f2f4f8;
      }
      @media only screen and (max-width: 360px) {
        margin-top: 9px;
        padding-top: 20px;
        .radio__icon {
          width: 30px;
          height: 30px;
        }
        .radio__label {
          margin-top: 27px;
        }
      }
      .radio__label {
        margin-top: 17px;
        letter-spacing: 0;
      }
    }
    .radio__button:checked+.radio__item {
      background-color: #60bb67;
      border-color: #60bb67;
      color: white;
      .radio__label {
        color: white;
      }
      .radio__icon {
        &:after {
          display: none;
          opacity: 0;
        }
      }
    }
  }
  // background icon for inputs
  .bg__icon {
    padding-left: 48px;
    background-position: 23px center;
    background-repeat: no-repeat;
    @media only screen and (max-width: 480px) {
      background-position: 11px center;
      padding-left: 35px;
    }
  }
  .bg__select-subject {
    background-image: url(../img/icons/select-subject.svg);
    @media only screen and (min-width: 768px) {
      width: 329px;
    }
  }
  .bg__pointer {
    background-image: url(../img/icons/pointer.svg);
    @media only screen and (min-width: 768px) {
      max-width: 150px;
    }
  }
  .bg__name {
    background-image: url(../img/icons/name.svg);
  }
  .bg__email {
    background-image: url(../img/icons/email.svg);
  }
  .bg__password {
    background-image: url(../img/icons/password.svg);
  }
  .bg__phone {
    background-image: url(../img/icons/phone.svg);
  }
  &__dots {
    position: absolute;
    right: -66px;
    bottom: -35px;
    z-index: -1;
    @media only screen and (max-width: 360px) {
      width: 85px;
      right: -13px;
      bottom: -14px;
    }
  }
  // restyle custom select
  .select_mate.form__elem {
    float: none;
    min-width: auto;
    padding-left: 0;
    box-shadow: none;
    min-height: auto;
    @media only screen and (max-width: 768px) {
      width: auto;
    }
  }
  .icon_select_mate {
    right: 20px;
  }
  .selecionado_opcion {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px 2% 5px 48px;
    opacity: 0.5;
    font-size: 16px;
    letter-spacing: 0.6px;
    color: darken(#bec9de, 10%);
    transform: translateY(2px);
    @media only screen and (max-width: 480px) {
      font-size: 13px;
    }
  }
  .cont_select_int {
    top: -1px;
  }
  .cont_select_int li {
    width: 100%;
    border-bottom: 1px solid #fff;
    background-color: #ffffff;
    list-style-type: none;
    padding: 10px 2% 10px 48px;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: left;
    color: #404852;
    transition: none;
    @media only screen and (max-width: 480px) {
      font-size: 13px;
    }
    &:hover {
      color: #ffffff;
      background-image: linear-gradient(131deg, #99d0f8, #699de1);
    }
  }
}

// select2 styles
.select2 .selection {
  .select2-selection {
    display: flex;
    align-items: center;
  }
  .select2-selection__arrow {
    height: 10px;
    width: 16px;
    position: absolute;
    top: 0px;
    right: 10px;
    bottom: 0;
    margin: auto;
    background-image: url(../img/icons/chevron-down.svg);
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.2s ease;
    b {
      display: none;
    }
  }
}

.select2-selection--single .select2-selection__rendered {
  padding-left: 0!important;
}

.select2 {
  width: 100%!important;
  .select2-selection__placeholder {
    color: #404852;
  }
  @media only screen and (max-width: 480px) {
    .select2-selection__placeholder {
      font-size: 13px!important;
    }
  }
}

.select2-dropdown {
  border: 1px solid #bec9de;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2);
}

.select2-results__option {
  padding-left: 23px!important;
}

.select2-container--open .select2-selection__arrow {
  transform: rotate(180deg);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  background-image: linear-gradient(131deg, #99d0f8, #699de1);
  color: white;
}

.section__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
  @media only screen and (max-width: 360px) {
    margin-bottom: 48px;
  }
}

.section__title {
  position: relative;
  margin-bottom: 1em;
  @include rfs(40);
  font-weight: 800;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #404852;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.75em;
    margin: auto;
    width: 55px;
    height: 12px;
    background-image: url(../img/icons/wave.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  @media only screen and (max-width: 360px) {
    font-size: 20px;
    &:after {
      bottom: -23px;
      width: 40px;
    }
  }
}

.section__desc {
  margin-top: 19px;
  max-width: 570px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 2.07;
  color: #797e85;
  @media only screen and (max-width: 360px) {
    margin-top: 22px;
    font-size: 13px;
    line-height: 1.6;
  }
}

// section partners
.section-partners {
  margin-top: 45px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
  .cont {
    max-width: 1100px;
    padding: 0 0 50px 0;
    border-bottom: solid 1px #dedede;
  }
  h4 {
    font-size: 20px;
    margin-right: 3vw;
    white-space: nowrap;
  }
  .partners__row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 20px;
    background-color: #fff;
    border-radius: 7px;
  }
}

// section-steps
.section-steps {
  position: relative;
  padding: 90px 0;
  @media only screen and (max-width: 1400px) {
    padding: 6.25vw 0;
  }
  @media only screen and (max-width: 360px) {
    padding-top: 17px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 85px;
      height: 43.44px;
      background-image: url(../img/dots.svg);
      background-size: cover;
    }
    &:before {
      left: -50px;
      top: 34%;
    }
    &:after {
      right: -50px;
      bottom: 13.2%;
    }
  }
  .step {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      align-content: center;
      text-align: center;
      margin-top: 40px;
      .step__circle {
        display: none;
      }
    }
    .col {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .col--text {
      padding: 10px 0 0 0;
    }
  }
  @media only screen and (min-width: 992px) {
    .step--inverse-order {
      &>.col:first-child {
        order: 2;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .steps--1,
    .steps--3 {
      margin-bottom: -5px;
      .step__pic {
        margin-right: 40px;
      }
    }
    .steps--2 {
      margin-top: -19px;
      margin-bottom: -5px;
      .col--text {
        padding: 40px 0 0 140px;
      }
    }
    .steps--3 {
      .step__pic {
        transform: translateY(-10px);
      }
    }
    .steps--4 {
      .step__pic {
        margin-left: 50px;
      }
      .col--text {
        padding: 14px 0 0 140px;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    .steps--1 {
      margin-top: 67px;
    }
    .steps--2 {
      margin-top: 35px;
    }
    .steps--3 {
      margin-top: 30px;
    }
    .steps--4 {
      margin-top: 43px;
    }
  }
  .linking-dots {
    display: block;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .linking-dots--flipped {
    transform: scaleY(-1);
  }
  .step__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: rgba(106, 191, 108, 0.1);
    border-radius: 50%;
  }
  .step__caption {
    position: relative;
    margin: 30px 0 43px;
    @media only screen and (max-width: 992px) {
      margin: 63px 0 20px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        margin: auto;
        width: 22px;
        height: 2px;
        border: solid 1.5px #67be6a;
      }
    }
    @media only screen and (max-width: 360px) {
      margin-top: 34px;
      margin-bottom: 22px;
      &:after {
        bottom: -13px;
      }
    }
  }
  .step__name {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px;
    @media only screen and (max-width: 360px) {
      font-size: 16px;
    }
  }
  .step__number {
    position: absolute;
    top: -20px;
    margin: auto;
    left: -50px;
    font-size: 80px;
    font-weight: 900;
    line-height: 0.85;
    letter-spacing: 1px;
    color: rgba(137, 159, 184, 0.1);
    &:after {
      content: "";
      position: absolute;
      right: 27%;
      bottom: -1px;
      display: block;
      width: 22px;
      height: 2px;
      border: solid 2px #67be6a;
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
    @media only screen and (max-width: 992px) {
      top: -0.65em;
      left: 0;
      right: 0;
      margin: auto;
      @include rfs(60);
    }
    @media only screen and (max-width: 360px) {
      font-size: 40px;
    }
  }
  .step__desc {
    max-width: 380px;
    font-size: 14px;
    font-weight: normal;
    line-height: 2;
    color: #797e85;
  }
  @media only screen and (max-width: 580px) {
    .step__pic {
      max-width: 57vw;
    }
    .step__name {
      @include rfs(18);
    }
    .step__desc {
      line-height: 1.5;
    }
  }
}

// section slider tutors
.slider-tutor {}

.section__slider {
  position: relative;
  margin-top: 20px;
  padding: 133px 0 60px;
  background-image: url(../img/bg-wave-grey.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: right top;
  @media only screen and (max-width: 1400px) {
    padding: 7vw 0;
  }
  @media only screen and (max-width: 360px) {
    padding: 54px 0 17px;
  }
  .section__header {
    margin-bottom: 10px;
  }
  .slick-slider {
    position: relative;
    padding-top: 0;
  }
  .slick-list {
    padding: 50px 0!important;
    @media only screen and (max-width: 360px) {
      padding: 37px 0 !important;
    }
  }
  .slick-slide:not(.slick-active) {
    opacity: 0.6;
    transition: all 0.3s ease;
  }
  .slider__slide {
    position: relative;
    max-width: 568px;
    margin: 0 35px;
    padding: 50px;
    min-height: 350px;
    font-size: 14px;
    line-height: normal;
    color: #797e85;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 40px 90px 0 rgba(198, 203, 214, 0.14);
    @media only screen and (max-width: 992px) {
      margin: 0 15px;
    }
    @media only screen and (max-width: 630px) {
      position: static;
      max-width: 85vw;
      margin: 0 5px;
      padding: 6.25vw 5vw 36px;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    content: "";
  }
  .slick-next {
    right: calc(50% - 380px);
    transform: scaleX(-1);
    transform-origin: top top;
  }
  .slick-prev {
    left: calc(50% - 380px);
    transform: translate(0, 0);
  }
  @media only screen and (max-width: 992px) {
    .slick-next {
      right: calc(50% - 305px);
    }
    .slick-prev {
      left: calc(50% - 305px);
    }
  }
  .slick-next,
  .slick-prev {
    top: 35%;
    width: 50px;
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(162, 171, 191, 0.29);
    border-radius: 50%;
    background-image: url(../img/icons/arrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 2;
    @media only screen and (max-width: 630px) {
      display: none!important;
    }
  }
  .slide__dots {
    position: absolute;
    top: -35px;
    left: -35px;
    z-index: -1;
  }
  .slider__person {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 630px) {
      flex-direction: column;
      .person__name {
        margin-top: 18px;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
  .person__circle {
    flex-shrink: 0;
    margin-bottom: 10px;
    width: 70px;
    height: 70px;
    margin-right: 30px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    @media only screen and (max-width: 630px) {
      margin-right: 0;
      margin-top: 7px;
      margin-bottom: 3px;
    }
    @media only screen and (max-width: 360px) {
      width: 50px;
      height: 50px;
    }
  }
  .person__name {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.7px;
    color: #404852;
    line-height: 1;
    @media only screen and (max-width: 360px) {
      font-size: 16px;
    }
  }
  .slider__data {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 31px 0 30px;
    border-bottom: solid 1px #dee0ef;
  }
  .data__country {
    display: flex;
    align-items: center;
    .circle {
      display: block;
      width: 20px;
      height: 18px;
      margin-right: 10px;
      // border-radius: 50%;
      // overflow: hidden;
    }
  }
  @media only screen and (max-width: 630px) {
    .slider__data {
      padding: 10px 0 0px;
    }
    .data-elem {
      flex-basis: 48%;
      margin-bottom: 25px;
    }
    .data__country {
      order: 2;
    }
    .data__hours {
      order: 3;
    }
    .data__reviews {
      order: 5;
    }
    .data__rating {
      order: 2;
    }
    .oval-dot {
      margin-bottom: 25px;
    }
    .slider__desc {
      text-align: center;
    }
    .slide__dots {
      display: none;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 85px;
      height: 43.44px;
      background-image: url(../img/dots.svg);
      background-size: cover;
    }
    &:before {
      left: 0;
      top: 5px;
    }
    &:after {
      bottom: 7px;
      right: 0;
    }
  }
  @media only screen and (max-width: 360px) {
    .slider__data {
      padding: 20px 0 0px;
    }
    .data-elem {
      margin-bottom: 21px;
      font-size: 13px;
    }
    .oval-dot {
      margin-bottom: 25px;
    }
    .slider__desc {
      text-align: center;
    }
    .slide__dots {
      display: none;
    }
  }
  .oval-dot {
    width: 2px;
    height: 2px;
    background-color: #8896b3;
    border-radius: 50%;
  }
  .data__stars {
    display: flex;
    align-items: center;
    .icon-star {
      margin: 0 2.5px;
    }
  }
  .data__hours {
    display: flex;
    align-items: center;
    .icon-clock {
      margin-right: 4px;
    }
  }
  .slider__desc {
    padding-top: 33px;
    line-height: 2;
    color: #83878c;
    @media only screen and (max-width: 360px) {
      padding-top: 20px;
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 630px) {
  .hidden-down-sm {
    display: none;
  }
}

@media only screen and (min-width: 630px) {
  .hidden-up-sm {
    display: none;
  }
}

.hidden-svg {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

// customize range input
.range-input {
  font-size: 15px;
  color: #404852;
  .range__track {
    margin: 38px 0 23px;
    @media only screen and (max-width: 360px) {
      margin: 29px 0 23px;
    }
  }
  .noUi-horizontal {
    height: 4px;
    background-color: #f1f4f9;
    border: none;
    .noUi-connect {
      background: #41bb4f;
    }
  }
  .noUi-horizontal .noUi-handle {
    right: -15px!important;
    top: -7px;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    box-shadow: 0 1px 8px 1px rgba(152, 158, 177, 0.5);
    border: none;
    border-radius: 50%;
    &:before,
    &:after {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
  .range__value span {
    letter-spacing: 1px;
  }
}

// timetable
.timetable {
  width: 100%;
  overflow: hidden;
  border-spacing: 0;
   &-wrap {
    margin-top: 27px;
    @media only screen and (max-width: 360px) {
      margin-top: 22px;
    }
  }
  p {
    margin-bottom: 0;
  }
  tr {
    &:first-child th:first-child {
      border-top-left-radius: 4px;
    }
    &:first-child th:last-child {
      border-top-right-radius: 4px;
      border-right: solid 0.5px #bec9de;
    }
    &:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      td {
        border-bottom: solid 0.5px #bec9de;
      }
      td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
    td:last-child {
      border-right: solid 0.5px #bec9de;
    }
  }
  th,
  td {
    width: 83px;
    padding: 0;
    border-top: solid 0.5px #bec9de;
    border-left: solid 0.5px #bec9de;
  }
  th {
    padding: 20px 0;
    font-size: 12px;
    line-height: normal;
    text-align: center;
    color: #404852;
    background-color: #fbfcff;
  }
  .timetable__period {
    padding: 14px 13px 20px 30px;
  }
  &__daypart {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: left;
    color: #404852;
  }
  &__subtime {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    color: #798ba3;
    white-space: nowrap;
  }
  .icon-clock {
    fill: #797e85;
    width: 18px;
    height: 18px;
    stroke: #798ba3;
  }
  .checkbox__button:checked+.checkbox__label {
    background-color: #60bb67;
    .icon-clock {
      stroke: #ffffff;
    }
  }
  .checkbox__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 80px;
    margin: 0;
    padding: 0!important;
    &:before,
    &:after {
      display: none!important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .radio__input-container {
    margin-top: 6px;
  }
}

// time-accordion
.time-accordion {
  .card {
    margin-top: 9px;
    border-radius: 4px;
    border: solid 1px #bec9de;
  }
  .card-body {
    border-top: solid 1px #d6dce6;
    padding: 0 20px 0;
  }
  .card-header {
    padding: 10px 20px 9px;
    border-bottom: none;
    .btn-link {
      position: relative;
      width: 100%;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      color: #404852;
      text-align: left;
      text-decoration: none;
      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 16px;
        height: 10px;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background-image: url(../img/icons/chevron-down.svg);
        background-repeat: no-repeat;
        transform: rotate(180deg);
        transition: transform 0.3s ease;
      }
      &.collapsed:after {
        transform: rotate(360deg);
      }
    }
    background-color: #ffffff;
  }
  .time__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    &:not(:first-child) {
      border-top: solid 1px #d6dce6;
    }
  }
  time {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #404852;
  }
}

// page step5
.stage-5 {
  .form-step-5 {
    display: block;
  }
  .header__phone {
    margin-bottom: 5px;
    @media only screen and (min-width: 992px) {
      margin-left: 38px;
      margin-bottom: 0;
    }
  }
  .main-menu {
    display: flex;
  }
  .order-form {
    margin-top: 97px;
    @media only screen and (max-width: 480px) {
      margin-top: 35px;
    }
  }
  .order__success {
    padding-top: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .form__title {
      max-width: 500px;
      margin-top: 20px;
      line-height: 1.4;
      @media only screen and (max-width: 360px) {
        margin-top: 4px;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    .icon-success {
      display: block;
      margin: 27px auto 45px;
      @media only screen and (max-width: 580px) {
        max-width: 56vw;
      }
      @media only screen and (max-width: 360px) {
        max-width: 54vw;
        margin: 4px auto 17px;
      }
    }
  }
}

// popup 
.modal {
  padding-right: 0!important;
}

.modal-dialog {
  max-width: 800px!important;
  position: relative;
  @media only screen and (max-width: 840px) {
    max-width: calc(100vw - 40px)!important;
  }
  @media only screen and (max-width: 400px) {
    max-width: calc(100vw - 20px)!important;
  }
  button.close {
    position: absolute;
    right: 45px;
    top: 40px;
    width: auto;
    margin: 0 0 0 auto;
    display: inline-block;
    float: none;
    font-size: 1rem;
    z-index: 1;
    @media only screen and (max-width: 840px) {
      right: 5.6vw;
      top: 5vw;
    }
    @media only screen and (max-width: 360px) {
      right: 14px;
      top: 14px;
      img {
        width: 16px;
      }
    }
  }
}

.order-form--popup {
  margin: 0!important;
  padding-bottom: 25px;
  .form__header {
    max-width: 370px;
    margin: 0 auto;
    position: relative;
    .form__title {
      margin: 20px 0 0 0;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
    }
    .order-form__dots {
      top: -43px;
      left: -40px;
      z-index: 0;
      opacity: 0.6;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 40px;
      .order-form__dots {
        top: auto;
        left: 33px;
        bottom: -40px;
        width: 75px;
      }
      .form__title {
        font-size: 20px;
      }
      .form__descr {
        font-size: 13px;
        line-height: 1.31;
      }
    }
  }
  .form__column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-group {
      flex-basis: 47%;
    }
    @media only screen and (max-width: 580px) {
      display: block;
    }
  }
  label {
    color: #797e85;
  }
  @media only screen and (max-width: 480px) {
    padding-bottom: 20px;
    .checkbox [type=checkbox]:checked+label,
    .checkbox [type=checkbox]:not(:checked)+label {
      margin-right: 10px;
      padding-bottom: 4px;
    }
    .copy-privacy p {
      margin-bottom: 0;
    }
  }
  .copy-privacy {
    align-items: flex-start;
    margin-bottom: 20px;
    p.text-small {
      margin-top: 0;
    }
  }
  .form__submit {
    margin-top: 10px;
    width: 100%;
  }
}

.mr-30px {
  margin-right: 30px;
}

.form-step-1,
.form-step-2,
.form-step-3,
.form-step-4,
.form-step-5,
.form__bottom,
.button--preview,
.main-menu {
  display: none;
}

.stage-1 {
  .form-step-1 {
    display: block;
  }
}

.stage-2,
.stage-3,
.stage-4 {
  .form-step-1 {
    display: none;
  }
  .form__bottom {
    display: block;
  }
  .form-step-2 {
    display: block;
  }
}

.stage-3,
.stage-4 {
  .form-step-2,
  .form-step-3 {
    display: block;
  }
}

.stage-4 {
  .form-step-2,
  .form-step-3,
  .form-step-4 {
    display: block;
  }
  .button--preview {
    display: flex;
  }
}

.stage-5 {
  .form-step-5 {
    display: block;
  }
  .form-step-2,
  .form-step-3,
  .form-step-4,
  .form__bottom,
  #formModal,
  .modal-backdrop {
    display: none!important;
  }
}

.warning[type=checkbox]:not(:checked)+label:before,
.warning+.select2,
.warning {
  border-color: #dc3545!important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, .25)!important;
  border-radius: 4px;
}