// custom radio buttons
.radio__group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.radio__input-container {
  position: relative;
  margin-top: 22px;
  &:not(:last-child) {
    margin-right: 41px;
  }
}

.radio__button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
  z-index: 1;
}

.radio__item {
  display: flex;
  align-items: center;
  margin-top: 15px;
  transition: all 300ms ease;
}

.radio__icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: solid 1px #60bb67;
  border-radius: 50%;
  &:after {
    content: "";
    display: none;
    width: 12.5px;
    height: 12.5px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
  }
}

.radio__label {
  margin-bottom: 0!important;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #404852;
  transition: all 300ms ease;
}

.radio__button:checked+.radio__item {
  .radio__icon {
    &:after {
      display: inline-block;
      background-color: #60bb67;
    }
  }
}