@charset "UTF-8";
.icon-phone { width: 0.88em; height: 1em; fill: #FFF; }

@keyframes fadeRaiseUp { from { opacity: 0;
    transform: translate3d(0, 20px, 0); }
  to { opacity: 1;
    transform: none; } }

.fadeRaiseUp { animation-name: fadeRaiseUp; animation-duration: 1s; animation-fill-mode: both; animation-timing-function: ease; }

.animated { animation-duration: 1s; animation-fill-mode: both; animation-timing-function: ease; }

@keyframes fadeInLeft { from { opacity: 0;
    transform: translate3d(-10%, 0, 0); }
  to { opacity: 1;
    transform: none; } }

.fadeInLeft { animation-name: fadeInLeft; }

@keyframes fadeInRight { from { opacity: 0;
    transform: translate3d(10%, 0, 0); }
  to { opacity: 1;
    transform: none; } }

.fadeInRight { animation-name: fadeInRight; }

@keyframes fadeInUp { from { opacity: 0;
    transform: translate3d(0, 20px, 0); }
  to { opacity: 1;
    transform: none; } }

.fadeInUp { animation-name: fadeInUp; }

@keyframes fadeInDown { from { opacity: 0;
    transform: translate3d(0, -20px, 0); }
  to { opacity: 1;
    transform: none; } }

.fadeInDown { animation-name: fadeInDown; }

@keyframes zoomIn { from { opacity: 0;
    transform: scale3d(0.95, 0.95, 0.95); }
  50% { opacity: 1; } }

.zoomIn { animation-name: zoomIn; }

.animated.infinite { -webkit-animation-iteration-count: infinite; animation-iteration-count: infinite; }

.animated.hinge { -webkit-animation-duration: 2s; animation-duration: 2s; }

/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling; These styles are required for noUiSlider to function. You don't need to change these rules to apply your design. */
.noUi-target, .noUi-target * { -webkit-touch-callout: none; -webkit-tap-highlight-color: transparent; -webkit-user-select: none; -ms-touch-action: none; touch-action: none; -ms-user-select: none; -moz-user-select: none; user-select: none; -moz-box-sizing: border-box; box-sizing: border-box; }

.noUi-target { position: relative; direction: ltr; }

.noUi-base, .noUi-connects { width: 100%; height: 100%; position: relative; z-index: 1; }

/* Wrapper for all connect elements. */
.noUi-connects { overflow: hidden; z-index: 0; }

.noUi-connect, .noUi-origin { will-change: transform; position: absolute; z-index: 1; top: 0; left: 0; height: 100%; width: 100%; -ms-transform-origin: 0 0; -webkit-transform-origin: 0 0; transform-origin: 0 0; }

/* Offset direction */
html:not([dir="rtl"]) .noUi-horizontal .noUi-origin { left: auto; right: 0; }

/* Give origins 0 height/width so they don't interfere with clicking the connect elements. */
.noUi-vertical .noUi-origin { width: 0; }

.noUi-horizontal .noUi-origin { height: 0; }

.noUi-handle { position: absolute; }

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin { -webkit-transition: transform 0.3s; transition: transform 0.3s; }

.noUi-state-drag * { cursor: inherit !important; }

/* Slider size and handle placement; */
.noUi-horizontal { height: 18px; }

.noUi-horizontal .noUi-handle { width: 34px; height: 28px; left: -17px; top: -6px; }

.noUi-vertical { width: 18px; }

.noUi-vertical .noUi-handle { width: 28px; height: 34px; left: -6px; top: -17px; }

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle { right: -17px; left: auto; }

/* Styling; Giving the connect element a border radius causes issues with using transform: scale */
.noUi-target { background: #FAFAFA; border-radius: 4px; border: 1px solid #D3D3D3; box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB; }

.noUi-connects { border-radius: 3px; }

.noUi-connect { background: #3FB8AF; }

/* Handles and cursors; */
.noUi-draggable { cursor: ew-resize; }

.noUi-vertical .noUi-draggable { cursor: ns-resize; }

.noUi-handle { border: 1px solid #D9D9D9; border-radius: 3px; background: #FFF; cursor: default; box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active { box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Handle stripes; */
.noUi-handle:before, .noUi-handle:after { content: ""; display: block; position: absolute; height: 14px; width: 1px; background: #E8E7E6; left: 14px; top: 6px; }

.noUi-handle:after { left: 17px; }

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after { width: 14px; height: 1px; left: 6px; top: 14px; }

.noUi-vertical .noUi-handle:after { top: 17px; }

/* Disabled state; */
[disabled] .noUi-connect { background: #B8B8B8; }

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle { cursor: not-allowed; }

/* Base; */
.noUi-pips, .noUi-pips * { -moz-box-sizing: border-box; box-sizing: border-box; }

.noUi-pips { position: absolute; color: #999; }

/* Values; */
.noUi-value { position: absolute; white-space: nowrap; text-align: center; }

.noUi-value-sub { color: #ccc; font-size: 10px; }

/* Markings; */
.noUi-marker { position: absolute; background: #CCC; }

.noUi-marker-sub { background: #AAA; }

.noUi-marker-large { background: #AAA; }

/* Horizontal layout; */
.noUi-pips-horizontal { padding: 10px 0; height: 80px; top: 100%; left: 0; width: 100%; }

.noUi-value-horizontal { -webkit-transform: translate(-50%, 50%); transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal { -webkit-transform: translate(50%, 50%); transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker { margin-left: -1px; width: 2px; height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub { height: 10px; }

.noUi-marker-horizontal.noUi-marker-large { height: 15px; }

/* Vertical layout; */
.noUi-pips-vertical { padding: 0 10px; height: 100%; top: 0; left: 100%; }

.noUi-value-vertical { -webkit-transform: translate(0, -50%); transform: translate(0, -50%, 0); padding-left: 25px; }

.noUi-rtl .noUi-value-vertical { -webkit-transform: translate(0, 50%); transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker { width: 5px; height: 2px; margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub { width: 10px; }

.noUi-marker-vertical.noUi-marker-large { width: 15px; }

.noUi-tooltip { display: block; position: absolute; border: 1px solid #D9D9D9; border-radius: 3px; background: #fff; color: #000; padding: 5px; text-align: center; white-space: nowrap; }

.noUi-horizontal .noUi-tooltip { -webkit-transform: translate(-50%, 0); transform: translate(-50%, 0); left: 50%; bottom: 120%; }

.noUi-vertical .noUi-tooltip { -webkit-transform: translate(0, -50%); transform: translate(0, -50%); top: 50%; right: 120%; }

.checkbox { /* Base for label styling */ /* checkbox aspect */ /* checked mark aspect */ /* checked mark aspect changes */ /* disabled checkbox */ /* accessibility */ /* hover style just for information */ }

.checkbox [type="checkbox"]:not(:checked), .checkbox [type="checkbox"]:checked { position: absolute; left: -9999px; }

.checkbox [type="checkbox"]:not(:checked) + label, .checkbox [type="checkbox"]:checked + label { position: relative; padding-left: 24px; margin-right: 17px; cursor: pointer; }

.checkbox [type="checkbox"]:not(:checked) + label:before, .checkbox [type="checkbox"]:checked + label:before { content: ''; position: absolute; left: 0; top: 0; width: 24px; height: 24px; border-radius: 2px; border: 1px solid #5cb965; background: #fff; transition: all .2s; }

.checkbox [type="checkbox"]:not(:checked) + label:after, .checkbox [type="checkbox"]:checked + label:after { content: ''; position: absolute; display: inline-block; width: 13px; height: 10px; top: 7px; left: 6px; font-size: 16px; line-height: 1; color: #5cb965; transition: all .2s; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABtSURBVHgBlZABDYAwDAQrAQmTMClIwQF1sDlBSiUgAQmlhA9pSBhtk8+ypvfdjyhRqjpl5i+gmA4TZ4AdKlFIsKV4l+3LwfpN71p8s7rV9QWsAHj05sfRzhn3Tj9hBYMNGYSCoTvA+E8B5BFwAl4LmLoQZ8CFAAAAAElFTkSuQmCC); }

.checkbox [type="checkbox"]:not(:checked) + label:after { opacity: 0; transform: scale(0); }

.checkbox [type="checkbox"]:checked + label:before { background-color: #5cb965; }

.checkbox [type="checkbox"]:checked + label:after { opacity: 1; transform: scale(1); color: #ffffff; }

.checkbox [type="checkbox"]:disabled:not(:checked) + label:before, .checkbox [type="checkbox"]:disabled:checked + label:before { box-shadow: none; border-color: #bbb; background-color: #ddd; }

.checkbox [type="checkbox"]:disabled:checked + label:after { color: #999; }

.checkbox [type="checkbox"]:disabled + label { color: #aaa; }

@media only screen and (max-width: 480px) { .checkbox [type=checkbox]:checked + label, .checkbox [type=checkbox]:not(:checked) + label { position: relative; padding-left: 16px; padding-bottom: 1px; margin-right: 0; }
  .checkbox [type=checkbox]:checked + label:before, .checkbox [type=checkbox]:not(:checked) + label:before { width: 16px; height: 16px; }
  .checkbox [type=checkbox]:checked + label:after, .checkbox [type=checkbox]:not(:checked) + label:after { top: 5px; left: 3px; background-size: 10px; background-repeat: no-repeat; } }

.radio__group { display: flex; flex-wrap: wrap; width: 100%; }

.radio__input-container { position: relative; margin-top: 22px; }

.radio__input-container:not(:last-child) { margin-right: 41px; }

.radio__button { opacity: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; margin: 0; cursor: pointer; z-index: 1; }

.radio__item { display: flex; align-items: center; margin-top: 15px; transition: all 300ms ease; }

.radio__icon { position: relative; width: 24px; height: 24px; margin-right: 10px; border: solid 1px #60bb67; border-radius: 50%; }

.radio__icon:after { content: ""; display: none; width: 12.5px; height: 12.5px; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; border-radius: 50%; }

.radio__label { margin-bottom: 0 !important; font-size: 14px; font-weight: bold; line-height: normal; letter-spacing: 0.5px; color: #404852; transition: all 300ms ease; }

.radio__button:checked + .radio__item .radio__icon:after { display: inline-block; background-color: #60bb67; }

/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:before, .slick-track:after { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir="rtl"] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

/* Slider */
/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0px; font-size: 0px; cursor: pointer; background: transparent; color: transparent; top: 50%; -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%); transform: translate(0, -50%); padding: 0; border: none; outline: none; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }

[dir="rtl"] .slick-prev { left: auto; right: -25px; }

.slick-prev:before { content: "←"; }

[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }

[dir="rtl"] .slick-next { left: -25px; right: auto; }

.slick-next:before { content: "→"; }

[dir="rtl"] .slick-next:before { content: "←"; }

/* Dots */
.slick-dotted.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: -25px; list-style: none; display: block; text-align: center; padding: 0; margin: 0; width: 100%; }

.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0 5px; padding: 0; cursor: pointer; }

.slick-dots li button { border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0px; font-size: 0px; color: transparent; padding: 5px; cursor: pointer; }

.slick-dots li button:hover, .slick-dots li button:focus { outline: none; }

.slick-dots li button:hover:before, .slick-dots li button:focus:before { opacity: 1; }

.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "•"; width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before { color: black; opacity: 0.75; }

/* Slider */
/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 20px; width: 20px; line-height: 0px; font-size: 0px; cursor: pointer; background: transparent; color: transparent; top: 50%; -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%); transform: translate(0, -50%); padding: 0; border: none; outline: none; }

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; background: transparent; color: transparent; }

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before { opacity: 1; }

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }

[dir="rtl"] .slick-prev { left: auto; right: -25px; }

.slick-prev:before { content: "←"; }

[dir="rtl"] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }

[dir="rtl"] .slick-next { left: -25px; right: auto; }

.slick-next:before { content: "→"; }

[dir="rtl"] .slick-next:before { content: "←"; }

/* Dots */
.slick-dotted.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: -25px; list-style: none; display: block; text-align: center; padding: 0; margin: 0; width: 100%; }

.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0 5px; padding: 0; cursor: pointer; }

.slick-dots li button { border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0px; font-size: 0px; color: transparent; padding: 5px; cursor: pointer; }

.slick-dots li button:hover, .slick-dots li button:focus { outline: none; }

.slick-dots li button:hover:before, .slick-dots li button:focus:before { opacity: 1; }

.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "•"; width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before { color: black; opacity: 0.75; }

.select2-container { box-sizing: border-box; display: inline-block; margin: 0; position: relative; vertical-align: middle; }

.select2-container .select2-selection--single { box-sizing: border-box; cursor: pointer; display: block; height: 28px; user-select: none; -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered { display: block; padding-left: 8px; padding-right: 20px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.select2-container .select2-selection--single .select2-selection__clear { position: relative; }

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered { padding-right: 8px; padding-left: 20px; }

.select2-container .select2-selection--multiple { box-sizing: border-box; cursor: pointer; display: block; min-height: 32px; user-select: none; -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered { display: inline-block; overflow: hidden; padding-left: 8px; text-overflow: ellipsis; white-space: nowrap; }

.select2-container .select2-search--inline { float: left; }

.select2-container .select2-search--inline .select2-search__field { box-sizing: border-box; border: none; font-size: 100%; margin-top: 5px; padding: 0; }

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button { -webkit-appearance: none; }

.select2-dropdown { background-color: white; border: 1px solid #aaa; border-radius: 4px; box-sizing: border-box; display: block; position: absolute; left: -100000px; width: 100%; z-index: 1051; }

.select2-results { display: block; }

.select2-results__options { list-style: none; margin: 0; padding: 0; }

.select2-results__option { padding: 6px; user-select: none; -webkit-user-select: none; }

.select2-results__option[aria-selected] { cursor: pointer; }

.select2-container--open .select2-dropdown { left: 0; }

.select2-container--open .select2-dropdown--above { border-bottom: none; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below { border-top: none; border-top-left-radius: 0; border-top-right-radius: 0; }

.select2-search--dropdown { display: block; padding: 4px; }

.select2-search--dropdown .select2-search__field { padding: 4px; width: 100%; box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button { -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide { display: none; }

.select2-close-mask { border: 0; margin: 0; padding: 0; display: block; position: fixed; left: 0; top: 0; min-height: 100%; min-width: 100%; height: auto; width: auto; opacity: 0; z-index: 99; background-color: #fff; filter: alpha(opacity=0); }

.select2-hidden-accessible { border: 0 !important; clip: rect(0 0 0 0) !important; -webkit-clip-path: inset(50%) !important; clip-path: inset(50%) !important; height: 1px !important; overflow: hidden !important; padding: 0 !important; position: absolute !important; width: 1px !important; white-space: nowrap !important; }

.select2-container--default .select2-selection--single { background-color: #fff; border: 1px solid #aaa; border-radius: 4px; }

.select2-container--default .select2-selection--single .select2-selection__rendered { color: #444; line-height: 28px; }

.select2-container--default .select2-selection--single .select2-selection__clear { cursor: pointer; float: right; font-weight: bold; }

.select2-container--default .select2-selection--single .select2-selection__placeholder { color: #999; }

.select2-container--default .select2-selection--single .select2-selection__arrow { height: 26px; position: absolute; top: 1px; right: 1px; width: 20px; }

.select2-container--default .select2-selection--single .select2-selection__arrow b { border-color: #888 transparent transparent transparent; border-style: solid; border-width: 5px 4px 0 4px; height: 0; left: 50%; margin-left: -4px; margin-top: -2px; position: absolute; top: 50%; width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear { float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow { left: 1px; right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single { background-color: #eee; cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear { display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b { border-color: transparent transparent #888 transparent; border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple { background-color: white; border: 1px solid #aaa; border-radius: 4px; cursor: text; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered { box-sizing: border-box; list-style: none; margin: 0; padding: 0 5px; width: 100%; }

.select2-container--default .select2-selection--multiple .select2-selection__rendered li { list-style: none; }

.select2-container--default .select2-selection--multiple .select2-selection__placeholder { color: #999; margin-top: 5px; float: left; }

.select2-container--default .select2-selection--multiple .select2-selection__clear { cursor: pointer; float: right; font-weight: bold; margin-top: 5px; margin-right: 10px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice { background-color: #e4e4e4; border: 1px solid #aaa; border-radius: 4px; cursor: default; float: left; margin-right: 5px; margin-top: 5px; padding: 0 5px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove { color: #999; cursor: pointer; display: inline-block; font-weight: bold; margin-right: 2px; }

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover { color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline { float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice { margin-left: 5px; margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove { margin-left: 2px; margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple { border: solid black 1px; outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple { background-color: #eee; cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove { display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple { border-top-left-radius: 0; border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple { border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field { border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field { background: transparent; border: none; outline: 0; box-shadow: none; -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options { max-height: 200px; overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] { padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] { color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] { background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option { padding-left: 1em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__group { padding-left: 0; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option { margin-left: -1em; padding-left: 2em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option { margin-left: -2em; padding-left: 3em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option { margin-left: -3em; padding-left: 4em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option { margin-left: -4em; padding-left: 5em; }

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option { margin-left: -5em; padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] { background-color: #5897fb; color: white; }

.select2-container--default .select2-results__group { cursor: default; display: block; padding: 6px; }

.select2-container--classic .select2-selection--single { background-color: #f7f7f7; border: 1px solid #aaa; border-radius: 4px; outline: 0; background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%); background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%); background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%); background-repeat: repeat-x; filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus { border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--single .select2-selection__rendered { color: #444; line-height: 28px; }

.select2-container--classic .select2-selection--single .select2-selection__clear { cursor: pointer; float: right; font-weight: bold; margin-right: 10px; }

.select2-container--classic .select2-selection--single .select2-selection__placeholder { color: #999; }

.select2-container--classic .select2-selection--single .select2-selection__arrow { background-color: #ddd; border: none; border-left: 1px solid #aaa; border-top-right-radius: 4px; border-bottom-right-radius: 4px; height: 26px; position: absolute; top: 1px; right: 1px; width: 20px; background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%); background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%); background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%); background-repeat: repeat-x; filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic .select2-selection--single .select2-selection__arrow b { border-color: #888 transparent transparent transparent; border-style: solid; border-width: 5px 4px 0 4px; height: 0; left: 50%; margin-left: -4px; margin-top: -2px; position: absolute; top: 50%; width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear { float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow { border: none; border-right: 1px solid #aaa; border-radius: 0; border-top-left-radius: 4px; border-bottom-left-radius: 4px; left: 1px; right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single { border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow { background: transparent; border: none; }

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b { border-color: transparent transparent #888 transparent; border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single { border-top: none; border-top-left-radius: 0; border-top-right-radius: 0; background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%); background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%); background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%); background-repeat: repeat-x; filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single { border-bottom: none; border-bottom-left-radius: 0; border-bottom-right-radius: 0; background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%); background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%); background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%); background-repeat: repeat-x; filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple { background-color: white; border: 1px solid #aaa; border-radius: 4px; cursor: text; outline: 0; }

.select2-container--classic .select2-selection--multiple:focus { border: 1px solid #5897fb; }

.select2-container--classic .select2-selection--multiple .select2-selection__rendered { list-style: none; margin: 0; padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__clear { display: none; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice { background-color: #e4e4e4; border: 1px solid #aaa; border-radius: 4px; cursor: default; float: left; margin-right: 5px; margin-top: 5px; padding: 0 5px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove { color: #888; cursor: pointer; display: inline-block; font-weight: bold; margin-right: 2px; }

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover { color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice { float: right; margin-left: 5px; margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove { margin-left: 2px; margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple { border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple { border-top: none; border-top-left-radius: 0; border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple { border-bottom: none; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field { border: 1px solid #aaa; outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field { outline: 0; box-shadow: none; }

.select2-container--classic .select2-dropdown { background-color: white; border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above { border-bottom: none; }

.select2-container--classic .select2-dropdown--below { border-top: none; }

.select2-container--classic .select2-results > .select2-results__options { max-height: 200px; overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] { padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] { color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] { background-color: #3875d7; color: white; }

.select2-container--classic .select2-results__group { cursor: default; display: block; padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown { border-color: #5897fb; }

html { box-sizing: border-box; }

*, *::before, *::after { box-sizing: inherit; }

html, body { font-family: "Nunito Sans", sans-serif; font-size: 16px; }

.wrapper { width: 100%; font-family: "Nunito Sans", sans-serif; color: #404852; letter-spacing: 0.5px; overflow: hidden; }

.cont { max-width: 1080px; margin: 0 auto; padding: 0 20px; }

a { transition: all 0.3s ease; color: inherit; }

a:hover { cursor: pointer; text-decoration: none; color: #86d97e; }

a:hover [class^="icon-"], a:hover [class*=" icon-"] { fill: #86d97e; }

[class^="icon-"], [class*=" icon-"] { transition: all 0.3s ease; }

.icon-phone { width: 16px; height: 16px; }

.button { display: flex; justify-content: center; align-items: center; box-shadow: none; width: 170px; height: 55px; font-size: 14px; font-weight: bold; line-height: normal; text-align: center; color: #5ecd6e; border-radius: 4px; border: solid 1px #47bc55; background-color: #ffffff; transition: all 0.3s ease; }

@media only screen and (max-width: 480px) { .button { height: 45px; font-size: 13px; } }

.button:focus, .button:hover { outline: none; cursor: pointer; }

a.button--green, .button--green, .button--green[type=submit] { margin-top: auto; color: #ffffff; border-color: transparent; border: none !important; background-color: #58b763; background-image: linear-gradient(128deg, #88cc7a, #58b763) !important; -webkit-appearance: none; }

a.button--green:hover, .button--green:hover, .button--green[type=submit]:hover { background-image: linear-gradient(-128deg, #88cc7a, #58b763); }

.oval-grey { display: flex; justify-content: center; align-items: center; width: 30px; height: 30px; background-color: rgba(106, 191, 108, 0.1); border-radius: 50%; }

.oval-grey .icon-study { width: 16px; height: 10px; }

.oval-grey .icon-schedule { width: 11px; height: 14px; }

.border-top { border-top: solid 1.5px #ebeef6; }

.color-green { color: #41bb4f; }

.text-underlined { text-decoration: underline; }

.text-small { font-size: 13px; }

.header { max-width: 1190px; margin: 0 auto; padding-top: 31px; }

@media only screen and (max-width: 768px) { .header { padding-top: 22px; } }

@media only screen and (max-width: 360px) { .header { padding-top: 11px; }
  .header .navbar { padding-left: 5px; } }

.header__logo img { width: 126px; }

@media only screen and (max-width: 360px) { .header__logo img { width: 76px; } }

.header__navbar { font-weight: 600; font-style: normal; font-stretch: normal; line-height: normal; color: #ffffff; transform: translateY(-4px); }

@media only screen and (max-width: 992px) { .header__navbar { padding-top: 20px; } }

@media only screen and (max-width: 768px) { .header .navbar { padding: 0 0.5rem; } }

.header__phone { display: flex; align-items: center; margin-left: auto; padding: 0; font-size: 14px; font-weight: 600; text-align: left; }

.header__phone .icon-phone { margin-right: 11px; }

@media only screen and (max-width: 360px) { .header__phone { font-size: 13px; }
  .header__phone .icon-phone { width: 11px; height: 11px; margin-right: 9px; } }

.header .main-menu { align-items: center; }

@media only screen and (max-width: 991px) { .header .main-menu { align-items: stretch; border-top: 1px solid #fff; border-bottom: 1px solid #fff; } }

.header .main-menu .nav-item:not(:last-child) { margin-right: 1em; }

@media only screen and (max-width: 992px) { .header .main-menu .nav-item:not(:last-child) { margin-right: 0; } }

.header .main-menu .nav-item:hover .nav-link { color: #86d97e; }

.header .main-menu .nav-item .dropdown-toggle::after { display: none; }

.header .main-menu .nav-link { font-size: 14px; padding: .35em 8px; color: #ffffff; transition: all 0.3s ease; cursor: pointer; }

.header .main-menu .nav-link.button { width: 128px; height: 45px; border-radius: 4px; border: solid 1px #ffffff; background-color: transparent; }

.header .main-menu .nav-link.button:hover { border-color: #86d97e; }

@media only screen and (max-width: 991px) { .header .main-menu .nav-link { padding-left: 0; }
  .header .main-menu .nav-link.button { margin-bottom: 10px; width: 100px; height: 35px; } }

.header .main-menu .dropdown-menu { min-width: 10rem; padding: 0; margin: 0; font-size: 1.25em; border: none; border-radius: 0; box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1), 0 8px 15px 0 rgba(0, 0, 0, 0.1); }

.header .main-menu .dropdown-item { color: #86d97e; padding: .25rem 0.6em; }

.header .main-menu .dropdown-item:hover { color: #86d97e; background: transparent; }

.header .main-menu .dropdown-item:active { color: #ffffff; background-color: #abe5a5; }

.header .navbar-toggler { border: none; color: #fff; padding: 0; font-size: 1rem; }

.header .navbar-toggler:focus { outline: none !important; }

.header .navbar-toggler-icon { width: 2em; height: 2em; background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255,255,255)' stroke-width='3' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }

@media only screen and (min-width: 480px) { .header .navbar-toggler-icon { margin-bottom: 0.7rem; } }

.hero { position: relative; min-height: 827px; color: #ffffff; padding-bottom: 76px; }

@media only screen and (max-width: 1400px) { .hero { min-height: 57.4vw; padding-bottom: 5.27vw; } }

.hero:before { content: ""; position: absolute; left: 0; bottom: 0; width: 100%; height: 100%; background-image: linear-gradient(to bottom, #f5f6f8, #ffffff); z-index: -2; }

.hero__text { max-width: 840px; margin: 189px auto 0; font-size: 20px; }

@media (max-width: 1200px) { .hero__text { font-size: calc(14.6px + 0.45vw) ; } }

@media only screen and (max-width: 1400px) { .hero__text { margin-top: 10vw; } }

@media only screen and (max-width: 480px) { .hero__text { margin-top: 45px; } }

.hero__title { font-family: 'Montserrat', sans-serif; font-weight: 700; font-size: 50px; font-style: normal; font-stretch: normal; line-height: normal; letter-spacing: 2px; }

@media (max-width: 1200px) { .hero__title { font-size: calc(17.6px + 2.7vw) ; } }

@media only screen and (max-width: 480px) { .hero__title { font-size: 20px; } }

.hero__subtitle { margin-top: 30px; font-size: 14px; line-height: 2; text-align: left; color: #e7e6e6; }

@media only screen and (max-width: 1400px) { .hero__subtitle { margin-top: 1.5vw; } }

@media only screen and (max-width: 480px) { .hero__subtitle { margin-top: 16px; font-size: 13px; line-height: 1.38; } }

.hero__title, .hero__subtitle { max-width: 700px; padding-left: 20px; }

@media only screen and (max-width: 992px) { .hero__title, .hero__subtitle { margin-left: auto; margin-right: auto; padding-left: 0; text-align: center; } }

.hero__bg { position: absolute; left: 0; top: 0; z-index: -1; height: auto; width: 100%; }

@media only screen and (max-width: 1200px) { .hero__bg { width: calc(100% + 100px); } }

@media only screen and (max-width: 992px) { .hero__bg { width: calc(100% + 150px); } }

@media only screen and (max-width: 580px) { .hero__bg { width: auto; height: 370px; } }

.order-form { padding: 43px 50px; position: relative; margin: 0 auto; margin-top: 88px; max-width: 850px; color: #404852; border-radius: 7px; background-color: #ffffff; box-shadow: 0 40px 60px 0 rgba(145, 159, 188, 0.17); }

@media only screen and (max-width: 1400px) { .order-form { margin-top: 6vw; } }

@media only screen and (max-width: 892px) { .order-form { padding: 20px; } }

@media only screen and (max-width: 480px) { .order-form { margin-top: 35px; padding: 20px 24px 10px; } }

.order-form.max-width-840 { margin-top: 99px; max-width: 840px; padding: 40px 60px 21px; }

@media only screen and (max-width: 1400px) { .order-form.max-width-840 { margin-top: 6vw; } }

@media only screen and (max-width: 480px) { .order-form.max-width-840 { padding: 19px 25px 0; margin-top: 35px; font-size: 20px; } }

.order-form .form-group { display: flex; flex-direction: column; justify-content: flex-end; margin-bottom: 20px; }

.order-form .form-group p.text-small { margin: 10px 0 0; }

.order-form .form__row { display: flex; justify-content: space-between; }

@media only screen and (max-width: 768px) { .order-form .column-md-down { flex-direction: column; align-items: center; }
  .order-form .column-md-down .form-group { width: 100%; max-width: 480px; }
  .order-form .column-md-down .button { width: 100%; } }

@media only screen and (min-width: 360px) { .order-form .form__top { padding-left: 11px; } }

@media only screen and (max-width: 360px) { .order-form .form__bottom { flex-direction: column; }
  .order-form .form__bottom .button { width: 100%; margin: 0; margin-bottom: 20px; } }

.order-form .form__back { display: flex; align-items: center; font-size: 14px; color: #404852; }

.order-form .form__back:hover { color: #6b7ea6; cursor: pointer; }

.order-form .form__back img { margin-right: 12px; }

.order-form .form__title { margin: 1.95em 0 .4em; font-size: 25px; font-weight: 600; }

@media (max-width: 1200px) { .order-form .form__title { font-size: calc(15.1px + 0.825vw) ; } }

.order-form .form__descr { margin-top: 5px; font-size: 16px; font-weight: normal; line-height: 1.75; letter-spacing: 0.6px; color: #797e85; }

.order-form .form__block { padding: 42.5px 0; }

.order-form .form__block-header { display: flex; align-items: center; margin-bottom: 0; font-size: 16px; color: #9eabba; }

.order-form .form__block-header .oval-grey { margin-right: 20px; }

@media only screen and (max-width: 360px) { .order-form .form__block { padding: 30px 0; }
  .order-form .form__block-header { font-size: 14px; line-height: 1.3; }
  .order-form .form__block-header .oval-grey { display: none; } }

@media only screen and (min-width: 480px) { .order-form .form__block.pixel-perfect-padding { padding-top: 47px; }
  .order-form .form__block.pixel-perfect-padding2 { padding-top: 30px; }
  .order-form .form__block.pixel-perfect-padding3 { padding-top: 40px; padding-bottom: 40px; } }

@media only screen and (max-width: 360px) { .order-form .form__block.pixel-perfect-padding0 { padding-top: 8px; }
  .order-form .form__block.pixel-perfect-padding3 { padding-top: 33px; } }

.order-form label { margin-bottom: 0.8rem; font-size: 16px; font-weight: 600; }

@media only screen and (max-width: 480px) { .order-form label { margin-bottom: .6rem; font-size: 13px; } }

.order-form .form__elem { display: block; height: 55px; border-radius: 4px; border: solid 1px #bec9de; }

@media only screen and (max-width: 480px) { .order-form .form__elem { height: 45px; } }

.order-form input, .order-form textarea { color: #404852; }

.order-form input::placeholder, .order-form textarea::placeholder { opacity: 0.5; font-size: 16px; font-weight: normal; font-style: normal; font-stretch: normal; line-height: normal; letter-spacing: 0.6px; color: #9cadcd; }

@media only screen and (max-width: 480px) { .order-form input::placeholder, .order-form textarea::placeholder { font-size: 13px; } }

.order-form textarea { width: 100%; min-height: 200px; resize: none; padding: 30px; }

.order-form textarea::placeholder { font-size: 13px; }

@media only screen and (max-width: 1400px) { .order-form textarea { padding: 2vw; } }

@media only screen and (max-width: 480px) { .order-form textarea { min-height: 126px; padding: 16px 15px; }
  .order-form textarea::placeholder { line-height: 1.7; font-size: 12px; } }

.order-form .form__row--description { margin-top: 25px; }

@media only screen and (max-width: 360px) { .order-form .form__row--description { margin-top: 21px; } }

.order-form .form__row--grade { margin-top: 6px; }

.order-form .form__row--grade .radio__group .radio__input-container { margin-top: 0; }

.order-form .form__row--grade .radio__group .radio__input-container:not(:last-child) { margin-right: 24px; }

@media only screen and (max-width: 360px) { .order-form .form__row--grade .radio__group .radio__input-container:not(:last-child) { margin-right: 10px; }
  .order-form .form__row--grade .radio__group .radio__input-container:nth-child(even) { margin-right: 0; }
  .order-form .form__row--grade .radio__group .radio__input-container .radio__item { width: 115px; } }

.order-form .form__row--grade .radio__item { flex-direction: column; width: 127px; min-height: 117px; padding: 25px 5px 10px; border-radius: 4px; border: solid 1px #d8dde8; }

.order-form .form__row--grade .radio__item .radio__icon { width: 40px; height: 40px; margin-right: 0; border: none; border-radius: 0; border: none; background-color: #f2f4f8; }

@media only screen and (max-width: 360px) { .order-form .form__row--grade .radio__item { margin-top: 9px; padding-top: 20px; }
  .order-form .form__row--grade .radio__item .radio__icon { width: 30px; height: 30px; }
  .order-form .form__row--grade .radio__item .radio__label { margin-top: 27px; } }

.order-form .form__row--grade .radio__item .radio__label { margin-top: 17px; letter-spacing: 0; }

.order-form .form__row--grade .radio__button:checked + .radio__item { background-color: #60bb67; border-color: #60bb67; color: white; }

.order-form .form__row--grade .radio__button:checked + .radio__item .radio__label { color: white; }

.order-form .form__row--grade .radio__button:checked + .radio__item .radio__icon:after { display: none; opacity: 0; }

.order-form .bg__icon { padding-left: 48px; background-position: 23px center; background-repeat: no-repeat; }

@media only screen and (max-width: 480px) { .order-form .bg__icon { background-position: 11px center; padding-left: 35px; } }

.order-form .bg__select-subject { background-image: url(../img/icons/select-subject.svg); }

@media only screen and (min-width: 768px) { .order-form .bg__select-subject { width: 329px; } }

.order-form .bg__pointer { background-image: url(../img/icons/pointer.svg); }

@media only screen and (min-width: 768px) { .order-form .bg__pointer { max-width: 150px; } }

.order-form .bg__name { background-image: url(../img/icons/name.svg); }

.order-form .bg__email { background-image: url(../img/icons/email.svg); }

.order-form .bg__password { background-image: url(../img/icons/password.svg); }

.order-form .bg__phone { background-image: url(../img/icons/phone.svg); }

.order-form__dots { position: absolute; right: -66px; bottom: -35px; z-index: -1; }

@media only screen and (max-width: 360px) { .order-form__dots { width: 85px; right: -13px; bottom: -14px; } }

.order-form .select_mate.form__elem { float: none; min-width: auto; padding-left: 0; box-shadow: none; min-height: auto; }

@media only screen and (max-width: 768px) { .order-form .select_mate.form__elem { width: auto; } }

.order-form .icon_select_mate { right: 20px; }

.order-form .selecionado_opcion { height: 100%; display: flex; align-items: center; margin: 0; padding: 5px 2% 5px 48px; opacity: 0.5; font-size: 16px; letter-spacing: 0.6px; color: #9cadcd; transform: translateY(2px); }

@media only screen and (max-width: 480px) { .order-form .selecionado_opcion { font-size: 13px; } }

.order-form .cont_select_int { top: -1px; }

.order-form .cont_select_int li { width: 100%; border-bottom: 1px solid #fff; background-color: #ffffff; list-style-type: none; padding: 10px 2% 10px 48px; font-size: 16px; font-weight: 600; font-style: normal; font-stretch: normal; line-height: normal; letter-spacing: 0.6px; text-align: left; color: #404852; transition: none; }

@media only screen and (max-width: 480px) { .order-form .cont_select_int li { font-size: 13px; } }

.order-form .cont_select_int li:hover { color: #ffffff; background-image: linear-gradient(131deg, #99d0f8, #699de1); }

.select2 .selection .select2-selection { display: flex; align-items: center; }

.select2 .selection .select2-selection__arrow { height: 10px; width: 16px; position: absolute; top: 0px; right: 10px; bottom: 0; margin: auto; background-image: url(../img/icons/chevron-down.svg); background-repeat: no-repeat; background-position: center; transition: transform 0.2s ease; }

.select2 .selection .select2-selection__arrow b { display: none; }

.select2-selection--single .select2-selection__rendered { padding-left: 0 !important; }

.select2 { width: 100% !important; }

.select2 .select2-selection__placeholder { color: #404852; }

@media only screen and (max-width: 480px) { .select2 .select2-selection__placeholder { font-size: 13px !important; } }

.select2-dropdown { border: 1px solid #bec9de; border-radius: 0px 0px 4px 4px; box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2); }

.select2-results__option { padding-left: 23px !important; }

.select2-container--open .select2-selection__arrow { transform: rotate(180deg); }

.select2-container--default .select2-results__option--highlighted[aria-selected] { background-color: #5897fb; background-image: linear-gradient(131deg, #99d0f8, #699de1); color: white; }

.section__header { display: flex; flex-direction: column; align-items: center; text-align: center; margin-bottom: 60px; }

@media only screen and (max-width: 360px) { .section__header { margin-bottom: 48px; } }

.section__title { position: relative; margin-bottom: 1em; font-size: 40px; font-weight: 800; line-height: normal; letter-spacing: normal; text-align: center; color: #404852; }

@media (max-width: 1200px) { .section__title { font-size: calc(16.6px + 1.95vw) ; } }

.section__title:after { content: ""; position: absolute; left: 0; right: 0; bottom: -0.75em; margin: auto; width: 55px; height: 12px; background-image: url(../img/icons/wave.svg); background-repeat: no-repeat; background-size: 100% 100%; }

@media only screen and (max-width: 360px) { .section__title { font-size: 20px; }
  .section__title:after { bottom: -23px; width: 40px; } }

.section__desc { margin-top: 19px; max-width: 570px; padding: 0 20px; font-size: 14px; line-height: 2.07; color: #797e85; }

@media only screen and (max-width: 360px) { .section__desc { margin-top: 22px; font-size: 13px; line-height: 1.6; } }

.section-partners { margin-top: 45px; }

@media only screen and (max-width: 480px) { .section-partners { display: none; } }

.section-partners .cont { max-width: 1100px; padding: 0 0 50px 0; border-bottom: solid 1px #dedede; }

.section-partners h4 { font-size: 20px; margin-right: 3vw; white-space: nowrap; }

.section-partners .partners__row { display: flex; align-items: center; justify-content: space-around; padding: 20px 20px; background-color: #fff; border-radius: 7px; }

.section-steps { position: relative; padding: 90px 0; }

@media only screen and (max-width: 1400px) { .section-steps { padding: 6.25vw 0; } }

@media only screen and (max-width: 360px) { .section-steps { padding-top: 17px; }
  .section-steps:before, .section-steps:after { content: ""; position: absolute; width: 85px; height: 43.44px; background-image: url(../img/dots.svg); background-size: cover; }
  .section-steps:before { left: -50px; top: 34%; }
  .section-steps:after { right: -50px; bottom: 13.2%; } }

.section-steps .step { display: flex; align-items: center; }

@media only screen and (max-width: 992px) { .section-steps .step { display: flex; flex-direction: column; align-content: center; text-align: center; margin-top: 40px; }
  .section-steps .step .step__circle { display: none; } }

.section-steps .step .col { flex-basis: 50%; display: flex; flex-direction: column; align-items: center; }

.section-steps .step .col--text { padding: 10px 0 0 0; }

@media only screen and (min-width: 992px) { .section-steps .step--inverse-order > .col:first-child { order: 2; } }

@media only screen and (min-width: 992px) { .section-steps .steps--1, .section-steps .steps--3 { margin-bottom: -5px; }
  .section-steps .steps--1 .step__pic, .section-steps .steps--3 .step__pic { margin-right: 40px; }
  .section-steps .steps--2 { margin-top: -19px; margin-bottom: -5px; }
  .section-steps .steps--2 .col--text { padding: 40px 0 0 140px; }
  .section-steps .steps--3 .step__pic { transform: translateY(-10px); }
  .section-steps .steps--4 .step__pic { margin-left: 50px; }
  .section-steps .steps--4 .col--text { padding: 14px 0 0 140px; } }

@media only screen and (max-width: 360px) { .section-steps .steps--1 { margin-top: 67px; }
  .section-steps .steps--2 { margin-top: 35px; }
  .section-steps .steps--3 { margin-top: 30px; }
  .section-steps .steps--4 { margin-top: 43px; } }

.section-steps .linking-dots { display: block; margin: 0 auto; }

@media only screen and (max-width: 992px) { .section-steps .linking-dots { display: none; } }

.section-steps .linking-dots--flipped { transform: scaleY(-1); }

.section-steps .step__circle { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; background-color: rgba(106, 191, 108, 0.1); border-radius: 50%; }

.section-steps .step__caption { position: relative; margin: 30px 0 43px; }

@media only screen and (max-width: 992px) { .section-steps .step__caption { margin: 63px 0 20px; }
  .section-steps .step__caption:after { content: ""; display: block; position: absolute; left: 0; right: 0; bottom: -10px; margin: auto; width: 22px; height: 2px; border: solid 1.5px #67be6a; } }

@media only screen and (max-width: 360px) { .section-steps .step__caption { margin-top: 34px; margin-bottom: 22px; }
  .section-steps .step__caption:after { bottom: -13px; } }

.section-steps .step__name { font-size: 20px; font-weight: bold; line-height: normal; letter-spacing: 1px; }

@media only screen and (max-width: 360px) { .section-steps .step__name { font-size: 16px; } }

.section-steps .step__number { position: absolute; top: -20px; margin: auto; left: -50px; font-size: 80px; font-weight: 900; line-height: 0.85; letter-spacing: 1px; color: rgba(137, 159, 184, 0.1); }

.section-steps .step__number:after { content: ""; position: absolute; right: 27%; bottom: -1px; display: block; width: 22px; height: 2px; border: solid 2px #67be6a; }

@media only screen and (max-width: 992px) { .section-steps .step__number:after { display: none; } }

@media only screen and (max-width: 992px) { .section-steps .step__number { top: -0.65em; left: 0; right: 0; margin: auto; font-size: 60px; } }

@media only screen and (max-width: 992px) and (max-width: 1200px) { .section-steps .step__number { font-size: calc(18.6px + 3.45vw) ; } }

@media only screen and (max-width: 360px) { .section-steps .step__number { font-size: 40px; } }

.section-steps .step__desc { max-width: 380px; font-size: 14px; font-weight: normal; line-height: 2; color: #797e85; }

@media only screen and (max-width: 580px) { .section-steps .step__pic { max-width: 57vw; }
  .section-steps .step__name { font-size: 18px; } }

@media only screen and (max-width: 580px) and (max-width: 1200px) { .section-steps .step__name { font-size: calc(14.4px + 0.3vw) ; } }

@media only screen and (max-width: 580px) { .section-steps .step__desc { line-height: 1.5; } }

.section__slider { position: relative; margin-top: 20px; padding: 133px 0 60px; background-image: url(../img/bg-wave-grey.svg); background-repeat: no-repeat; background-size: 100%; background-position: right top; }

@media only screen and (max-width: 1400px) { .section__slider { padding: 7vw 0; } }

@media only screen and (max-width: 360px) { .section__slider { padding: 54px 0 17px; } }

.section__slider .section__header { margin-bottom: 10px; }

.section__slider .slick-slider { position: relative; padding-top: 0; }

.section__slider .slick-list { padding: 50px 0 !important; }

@media only screen and (max-width: 360px) { .section__slider .slick-list { padding: 37px 0 !important; } }

.section__slider .slick-slide:not(.slick-active) { opacity: 0.6; transition: all 0.3s ease; }

.section__slider .slider__slide { position: relative; max-width: 568px; margin: 0 35px; padding: 50px; min-height: 350px; font-size: 14px; line-height: normal; color: #797e85; border-radius: 4px; background-color: #ffffff; box-shadow: 0 40px 90px 0 rgba(198, 203, 214, 0.14); }

@media only screen and (max-width: 992px) { .section__slider .slider__slide { margin: 0 15px; } }

@media only screen and (max-width: 630px) { .section__slider .slider__slide { position: static; max-width: 85vw; margin: 0 5px; padding: 6.25vw 5vw 36px; } }

.section__slider .slick-next:before, .section__slider .slick-prev:before { content: ""; }

.section__slider .slick-next { right: calc(50% - 380px); transform: scaleX(-1); transform-origin: top top; }

.section__slider .slick-prev { left: calc(50% - 380px); transform: translate(0, 0); }

@media only screen and (max-width: 992px) { .section__slider .slick-next { right: calc(50% - 305px); }
  .section__slider .slick-prev { left: calc(50% - 305px); } }

.section__slider .slick-next, .section__slider .slick-prev { top: 35%; width: 50px; height: 50px; background-color: #ffffff; box-shadow: 0 15px 30px 0 rgba(162, 171, 191, 0.29); border-radius: 50%; background-image: url(../img/icons/arrow.svg); background-repeat: no-repeat; background-position: center center; z-index: 2; }

@media only screen and (max-width: 630px) { .section__slider .slick-next, .section__slider .slick-prev { display: none !important; } }

.section__slider .slide__dots { position: absolute; top: -35px; left: -35px; z-index: -1; }

.section__slider .slider__person { display: flex; align-items: center; }

@media only screen and (max-width: 630px) { .section__slider .slider__person { flex-direction: column; }
  .section__slider .slider__person .person__name { margin-top: 18px; } }

.section__slider p { margin-bottom: 0; }

.section__slider .person__circle { flex-shrink: 0; margin-bottom: 10px; width: 70px; height: 70px; margin-right: 30px; border-radius: 50%; overflow: hidden; }

.section__slider .person__circle img { width: 100%; height: 100%; }

@media only screen and (max-width: 630px) { .section__slider .person__circle { margin-right: 0; margin-top: 7px; margin-bottom: 3px; } }

@media only screen and (max-width: 360px) { .section__slider .person__circle { width: 50px; height: 50px; } }

.section__slider .person__name { margin-bottom: 10px; font-size: 20px; font-weight: bold; letter-spacing: 0.7px; color: #404852; line-height: 1; }

@media only screen and (max-width: 360px) { .section__slider .person__name { font-size: 16px; } }

.section__slider .slider__data { display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; padding: 31px 0 30px; border-bottom: solid 1px #dee0ef; }

.section__slider .data__country { display: flex; align-items: center; }

.section__slider .data__country .circle { display: block; width: 20px; height: 18px; margin-right: 10px; }

@media only screen and (max-width: 630px) { .section__slider .slider__data { padding: 10px 0 0px; }
  .section__slider .data-elem { flex-basis: 48%; margin-bottom: 25px; }
  .section__slider .data__country { order: 2; }
  .section__slider .data__hours { order: 3; }
  .section__slider .data__reviews { order: 5; }
  .section__slider .data__rating { order: 2; }
  .section__slider .oval-dot { margin-bottom: 25px; }
  .section__slider .slider__desc { text-align: center; }
  .section__slider .slide__dots { display: none; }
  .section__slider:before, .section__slider:after { content: ""; position: absolute; width: 85px; height: 43.44px; background-image: url(../img/dots.svg); background-size: cover; }
  .section__slider:before { left: 0; top: 5px; }
  .section__slider:after { bottom: 7px; right: 0; } }

@media only screen and (max-width: 360px) { .section__slider .slider__data { padding: 20px 0 0px; }
  .section__slider .data-elem { margin-bottom: 21px; font-size: 13px; }
  .section__slider .oval-dot { margin-bottom: 25px; }
  .section__slider .slider__desc { text-align: center; }
  .section__slider .slide__dots { display: none; } }

.section__slider .oval-dot { width: 2px; height: 2px; background-color: #8896b3; border-radius: 50%; }

.section__slider .data__stars { display: flex; align-items: center; }

.section__slider .data__stars .icon-star { margin: 0 2.5px; }

.section__slider .data__hours { display: flex; align-items: center; }

.section__slider .data__hours .icon-clock { margin-right: 4px; }

.section__slider .slider__desc { padding-top: 33px; line-height: 2; color: #83878c; }

@media only screen and (max-width: 360px) { .section__slider .slider__desc { padding-top: 20px; line-height: 1.5; } }

@media only screen and (max-width: 630px) { .hidden-down-sm { display: none; } }

@media only screen and (min-width: 630px) { .hidden-up-sm { display: none; } }

.hidden-svg { position: absolute; width: 0; height: 0; opacity: 0; }

.range-input { font-size: 15px; color: #404852; }

.range-input .range__track { margin: 38px 0 23px; }

@media only screen and (max-width: 360px) { .range-input .range__track { margin: 29px 0 23px; } }

.range-input .noUi-horizontal { height: 4px; background-color: #f1f4f9; border: none; }

.range-input .noUi-horizontal .noUi-connect { background: #41bb4f; }

.range-input .noUi-horizontal .noUi-handle { right: -15px !important; top: -7px; width: 16px; height: 16px; background-color: #ffffff; box-shadow: 0 1px 8px 1px rgba(152, 158, 177, 0.5); border: none; border-radius: 50%; }

.range-input .noUi-horizontal .noUi-handle:before, .range-input .noUi-horizontal .noUi-handle:after { display: none; }

.range-input .noUi-horizontal .noUi-handle:focus { outline: none; }

.range-input .range__value span { letter-spacing: 1px; }

.timetable { width: 100%; overflow: hidden; border-spacing: 0; border-collapse: initial; }

.timetable-wrap { margin-top: 27px; }

@media only screen and (max-width: 360px) { .timetable-wrap { margin-top: 22px; } }

.timetable p { margin-bottom: 0; }

.timetable tr:first-child th:first-child { border-top-left-radius: 4px; }

.timetable tr:first-child th:last-child { border-top-right-radius: 4px; border-right: solid 0.5px #bec9de; }

.timetable tr:last-child td:first-child { border-bottom-left-radius: 4px; }

.timetable tr:last-child td { border-bottom: solid 0.5px #bec9de; }

.timetable tr:last-child td:last-child { border-bottom-right-radius: 4px; }

.timetable tr td:last-child { border-right: solid 0.5px #bec9de; }

.timetable th, .timetable td { width: 83px; padding: 0; border-top: solid 0.5px #bec9de; border-left: solid 0.5px #bec9de; }

.timetable th { padding: 20px 0; font-size: 12px; line-height: normal; text-align: center; color: #404852; background-color: #fbfcff; }

.timetable .timetable__period { padding: 14px 13px 20px 30px; }

.timetable__daypart { font-size: 18px; font-weight: 600; font-style: normal; font-stretch: normal; line-height: normal; letter-spacing: 0.6px; text-align: left; color: #404852; }

.timetable__subtime { font-size: 13px; font-weight: normal; font-style: normal; font-stretch: normal; line-height: normal; color: #798ba3; white-space: nowrap; }

.timetable .icon-clock { fill: #797e85; width: 18px; height: 18px; stroke: #798ba3; }

.timetable .checkbox__button:checked + .checkbox__label { background-color: #60bb67; }

.timetable .checkbox__button:checked + .checkbox__label .icon-clock { stroke: #ffffff; }

.timetable .checkbox__label { display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; min-height: 80px; margin: 0; padding: 0 !important; }

.timetable .checkbox__label:before, .timetable .checkbox__label:after { display: none !important; }

@media only screen and (max-width: 360px) { .radio__input-container { margin-top: 6px; } }

.time-accordion .card { margin-top: 9px; border-radius: 4px; border: solid 1px #bec9de; }

.time-accordion .card-body { border-top: solid 1px #d6dce6; padding: 0 20px 0; }

.time-accordion .card-header { padding: 10px 20px 9px; border-bottom: none; background-color: #ffffff; }

.time-accordion .card-header .btn-link { position: relative; width: 100%; padding: 0; font-size: 14px; font-weight: 600; line-height: normal; color: #404852; text-align: left; text-decoration: none; }

.time-accordion .card-header .btn-link:after { content: ""; position: absolute; display: inline-block; width: 16px; height: 10px; top: 0; bottom: 0; right: 0; margin: auto; background-image: url(../img/icons/chevron-down.svg); background-repeat: no-repeat; transform: rotate(180deg); transition: transform 0.3s ease; }

.time-accordion .card-header .btn-link.collapsed:after { transform: rotate(360deg); }

.time-accordion .time__row { width: 100%; display: flex; justify-content: space-between; align-items: center; padding: 15px 0; }

.time-accordion .time__row:not(:first-child) { border-top: solid 1px #d6dce6; }

.time-accordion time { font-size: 16px; font-weight: bold; line-height: normal; letter-spacing: 0.6px; color: #404852; }

.stage-5 .form-step-5 { display: block; }

.stage-5 .header__phone { margin-bottom: 5px; }

@media only screen and (min-width: 992px) { .stage-5 .header__phone { margin-left: 38px; margin-bottom: 0; } }

.stage-5 .main-menu { display: flex; }

.stage-5 .order-form { margin-top: 97px; }

@media only screen and (max-width: 480px) { .stage-5 .order-form { margin-top: 35px; } }

.stage-5 .order__success { padding-top: 17px; display: flex; flex-direction: column; align-items: center; }

.stage-5 .order__success .form__title { max-width: 500px; margin-top: 20px; line-height: 1.4; }

@media only screen and (max-width: 360px) { .stage-5 .order__success .form__title { margin-top: 4px; font-size: 16px; line-height: 1.5; } }

.stage-5 .order__success .icon-success { display: block; margin: 27px auto 45px; }

@media only screen and (max-width: 580px) { .stage-5 .order__success .icon-success { max-width: 56vw; } }

@media only screen and (max-width: 360px) { .stage-5 .order__success .icon-success { max-width: 54vw; margin: 4px auto 17px; } }

.modal { padding-right: 0 !important; }

.modal-dialog { max-width: 800px !important; position: relative; }

@media only screen and (max-width: 840px) { .modal-dialog { max-width: calc(100vw - 40px) !important; } }

@media only screen and (max-width: 400px) { .modal-dialog { max-width: calc(100vw - 20px) !important; } }

.modal-dialog button.close { position: absolute; right: 45px; top: 40px; width: auto; margin: 0 0 0 auto; display: inline-block; float: none; font-size: 1rem; z-index: 1; }

@media only screen and (max-width: 840px) { .modal-dialog button.close { right: 5.6vw; top: 5vw; } }

@media only screen and (max-width: 360px) { .modal-dialog button.close { right: 14px; top: 14px; }
  .modal-dialog button.close img { width: 16px; } }

.order-form--popup { margin: 0 !important; padding-bottom: 25px; }

.order-form--popup .form__header { max-width: 370px; margin: 0 auto; position: relative; }

.order-form--popup .form__header .form__title { margin: 20px 0 0 0; font-size: 30px; font-weight: bold; letter-spacing: 1px; text-align: center; }

.order-form--popup .form__header .order-form__dots { top: -43px; left: -40px; z-index: 0; opacity: 0.6; }

@media only screen and (max-width: 480px) { .order-form--popup .form__header { margin-bottom: 40px; }
  .order-form--popup .form__header .order-form__dots { top: auto; left: 33px; bottom: -40px; width: 75px; }
  .order-form--popup .form__header .form__title { font-size: 20px; }
  .order-form--popup .form__header .form__descr { font-size: 13px; line-height: 1.31; } }

.order-form--popup .form__column { display: flex; flex-wrap: wrap; justify-content: space-between; }

.order-form--popup .form__column .form-group { flex-basis: 47%; }

@media only screen and (max-width: 580px) { .order-form--popup .form__column { display: block; } }

.order-form--popup label { color: #797e85; }

@media only screen and (max-width: 480px) { .order-form--popup { padding-bottom: 20px; }
  .order-form--popup .checkbox [type=checkbox]:checked + label, .order-form--popup .checkbox [type=checkbox]:not(:checked) + label { margin-right: 10px; padding-bottom: 4px; }
  .order-form--popup .copy-privacy p { margin-bottom: 0; } }

.order-form--popup .copy-privacy { align-items: flex-start; margin-bottom: 20px; }

.order-form--popup .copy-privacy p.text-small { margin-top: 0; }

.order-form--popup .form__submit { margin-top: 10px; width: 100%; }

.mr-30px { margin-right: 30px; }

.form-step-1, .form-step-2, .form-step-3, .form-step-4, .form-step-5, .form__bottom, .button--preview, .main-menu { display: none; }

.stage-1 .form-step-1 { display: block; }

.stage-2 .form-step-1, .stage-3 .form-step-1, .stage-4 .form-step-1 { display: none; }

.stage-2 .form__bottom, .stage-3 .form__bottom, .stage-4 .form__bottom { display: block; }

.stage-2 .form-step-2, .stage-3 .form-step-2, .stage-4 .form-step-2 { display: block; }

.stage-3 .form-step-2, .stage-3 .form-step-3, .stage-4 .form-step-2, .stage-4 .form-step-3 { display: block; }

.stage-4 .form-step-2, .stage-4 .form-step-3, .stage-4 .form-step-4 { display: block; }

.stage-4 .button--preview { display: flex; }

.stage-5 .form-step-5 { display: block; }

.stage-5 .form-step-2, .stage-5 .form-step-3, .stage-5 .form-step-4, .stage-5 .form__bottom, .stage-5 #formModal, .stage-5 .modal-backdrop { display: none !important; }

.warning[type=checkbox]:not(:checked) + label:before, .warning + .select2, .warning { border-color: #dc3545 !important; box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important; border-radius: 4px; }
