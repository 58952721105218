// non WOW animation
@keyframes fadeRaiseUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeRaiseUp {
  animation-name: fadeRaiseUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

//end of non WOW animation
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

// fadeInLeft
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

// fadeInRight
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

// fadeInUp
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

// fadeInDown
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

// zoomIn
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.95, .95, .95);
  }
  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}