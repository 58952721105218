.checkbox {
  /* Base for label styling */
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }
  [type="checkbox"]:not(:checked)+label,
  [type="checkbox"]:checked+label {
    position: relative;
    padding-left: 24px;
    margin-right: 17px;
    cursor: pointer;
  }
  /* checkbox aspect */
  [type="checkbox"]:not(:checked)+label:before,
  [type="checkbox"]:checked+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid #5cb965;
    background: #fff;
    transition: all .2s;
  }
  /* checked mark aspect */
  [type="checkbox"]:not(:checked)+label:after,
  [type="checkbox"]:checked+label:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 13px;
    height: 10px;
    top: 7px;
    left: 6px;
    font-size: 16px;
    line-height: 1;
    color: #5cb965;
    transition: all .2s;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABtSURBVHgBlZABDYAwDAQrAQmTMClIwQF1sDlBSiUgAQmlhA9pSBhtk8+ypvfdjyhRqjpl5i+gmA4TZ4AdKlFIsKV4l+3LwfpN71p8s7rV9QWsAHj05sfRzhn3Tj9hBYMNGYSCoTvA+E8B5BFwAl4LmLoQZ8CFAAAAAElFTkSuQmCC);
  }
  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="checkbox"]:checked+label:before {
    background-color: #5cb965;
  }
  [type="checkbox"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
    color: #ffffff;
  }
  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked)+label:before,
  [type="checkbox"]:disabled:checked+label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  [type="checkbox"]:disabled:checked+label:after {
    color: #999;
  }
  [type="checkbox"]:disabled+label {
    color: #aaa;
  }
  /* accessibility */
  // [type="checkbox"]:checked:focus+label:before,
  // [type="checkbox"]:not(:checked):focus+label:before {
  //   border: 2px dotted blue;
  // }
  /* hover style just for information */
  // label:hover:before {
  //   border: 2px solid #4778d9!important;
  // }
}

@media only screen and (max-width: 480px) {
  .checkbox [type=checkbox]:checked+label,
  .checkbox [type=checkbox]:not(:checked)+label {
    position: relative;
    padding-left: 16px;
    padding-bottom: 1px;
    margin-right: 0;
    &:before {
      width: 16px;
      height: 16px;
    }
    &:after {
      top: 5px;
      left: 3px;
      background-size: 10px;
      background-repeat: no-repeat;
    }
  }
}